<template>
  <v-container fluid>
    <TableViewComponent
      title="Table applications"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter une application"
      itemLabel="application"
      itemsLabel="applications"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette application ?"
      :loading="loading"
      :vcols="{ xl: '7', lg: '9', md: '10', sm: '12', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"

    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { ApplicationService } from "@/service/dictionary/applications_service.js";
import ServicesService from "@/service/sfr/services_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableApplications",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],

      /**le classe d'accès au service */
      service: null,

      /**le service d'accès aux services */
      serviceServices: null,
    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() {
      this.$router.push(routes.applications.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];

        let entities = await this.service.getAll();

        let services = await this.serviceServices.getAllServices();

        entities.forEach((e) => {
          let entity = JSON.parse(JSON.stringify(e));
          entity.view = () => {
            this.$router.push(routes.applications.view.root + "/" + entity.id);
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(routes.applications.edit.root + "/" + entity.id);
          };

          entity.delete = async () => {
            try {
              await this.service.delete(entity.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de l'application : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          entity.labelIsgroup = entity.isgroup ? "oui" : "non";

          if (entity.serviceId) {
            entity.service = services.find(
              (s) => s.id === entity.serviceId
            ).name;
          } else {
            entity.service = "-";
          }

          entity.labelRanking = entity.ranking || "-";

          entity.labelIsnew = entity.isnew ? "oui" : "non";

          entity.labelIsactive = entity.isactive ? "oui" : "non";

          entity.labelOpenInNewTab = entity.openinnewtab ? "oui" : "non";

          this.entities.push(entity);
        });

        // logger.debug("A:" + JSON.stringify(entities, null, 4));
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Label",
          align: "start",
          sortable: true,
          value: "label",
        },
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          width: "200px",
          value: "digitalName",
        },
        {
          text: "Service",
          align: "start",
          sortable: true,
          value: "service",
        },
        {
          text: "Catégorie",
          align: "start",
          sortable: true,
          value: "serviceCategory",
        },
        {
          text: "Ordre catégorie",
          align: "start",
          sortable: true,
          value: "labelRanking",
        },
        {
          text: "Mots clés",
          align: "start",
          sortable: true,
          value: "keywords",
          default: false,
        },        
        {
          text: "App collaborateur",
          align: "start",
          sortable: true,
          value: "labelIsgroup",
        },        
        {
          text: "Nouveauté",
          align: "start",
          sortable: true,
          value: "labelIsnew",
          default: false,
        },
        {
          text: "Actif",
          align: "start",
          sortable: true,
          value: "labelIsactive",
          default: false,
        },
        {
          text: "Nouvel onglet",
          align: "start",
          sortable: true,
          value: "labelOpenInNewTab",
          default: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditApplications];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditApplications, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadApplications];
    },
  },
  mounted() {
    //on instancie les services
    this.service = new ApplicationService(this.$api.getApplicationApi());

    this.serviceServices = new ServicesService(this.$api);

    this.load();
  },
};
</script>

<style>
</style>